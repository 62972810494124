import React, { useContext, useState } from "react";
import styled from "styled-components";
import TitleBar from "../components/TitleBar";
import { IField } from "../interfaces/field-interface";
import Input from "../components/Input";
import { ISeachQuery } from "../interfaces/search-query-interface";
import Button from "../components/Button";
import DropdownGrid from "../components/Dropdown/DropdownGrid";
import SearchResultBar from "../components/SearchResultBar";
import ResultTable from "../components/ResultTable";
import { logData } from "../apis/log-data-api";
import { ILogData } from "../interfaces/log-data-interface";
import { LoadingContext } from "../contexts/LoadingContext";
import SnackBar from "../components/SnackBar";
import { UserContext } from "../contexts/UserContext";
import { defaultFields } from "../sources/field";
import { DropdownContext } from "../contexts/DropdownContext";
import moment from "moment";

const Home = () => {
  const { user } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { dropdownRef, setDropdownRef } = useContext(DropdownContext);
  const now = moment().set({ hour: 0, minute: 0 });
  const [logResult, setLogResult] = useState<ILogData[] | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<ISeachQuery>({
    startDate: `${moment().add(-1, "days").set({ hour: 0, minute: 0 })}`,
    endDate: `${now}`,
    Sender: "",
    "IP address": "",
    Recipient: "",
    Subject: "",
    "Message ID": "",
  });
  const renderInput = (field: IField) => {
    switch (field.type) {
      case "input":
        return (
          <Input
            placeholder={field.placeholder}
            onChange={(e: any) => {
              setSearchQuery({ ...searchQuery, [field.label]: e });
            }}
          />
        );
      case "calendar":
        return (
          <DropdownGrid
            data={{
              startDate: searchQuery["startDate"],
              endDate: searchQuery["endDate"],
            }}
            choices={field.choices}
            onSelect={(startDate: string, endDate: string) =>
              setSearchQuery({
                ...searchQuery,
                startDate: startDate,
                endDate: endDate,
              })
            }
          />
        );
    }
  };
  const handleSearch = async () => {
    if (!user) return;
    setLogResult(undefined);
    setIsLoading({ status: "inprogress", label: "Loading data..." });
    const result = await logData.get(
      user.idToken,
      user.company,
      searchQuery,
      user.email
    );
    setIsLoading(undefined);
    setLogResult(result);
  };
  return (
    <SContainer>
      {dropdownRef && (
        <SBackDrop
          onClick={() => {
            setDropdownRef(null);
          }}
        />
      )}
      <SnackBar />
      <TitleBar
        title={"Email Log Search"}
        userImageUrl={user?.imageUrl}
        userName={user?.name}
      />
      <SSeachSection>
        <SGridContainer>
          {/* render topic */}
          {defaultFields.map((field, i) => {
            return (
              <SGrid key={i} style={{ gridRowStart: field.row }}>
                {field.label}:
              </SGrid>
            );
          })}
          {/* render input */}
          {defaultFields.map((field, i) => {
            return (
              <SGripInput
                key={i}
                style={{ gridRowStart: field.row, gridColumn: field.span }}
              >
                {renderInput(field)}
              </SGripInput>
            );
          })}
        </SGridContainer>
        <Button
          onclick={() => handleSearch()}
          addonCss={{
            background: "#3C7FEC",
            color: "white",
            margin: "20px 0px 0px 9.5%",
          }}
        >
          SEARCH
        </Button>
      </SSeachSection>
      {logResult && (
        <SResultContainer>
          <SearchResultBar data={[...logResult]} searchQuery={searchQuery} />
          <ResultTable
            headers={[
              "Message ID",
              "Date",
              "Sender",
              "Sender Message size",
              "Subject",
              "Direction",
              "Attachments",
              "Recipient address",
              "Event target IP address",
            ]}
            resultOrder={[
              "messageId",
              "date",
              "sender",
              "senderMessageSize",
              "subject",
              "direction",
              "attachments",
              "recipientAddress",
              "eventTargetIPAddress",
            ]}
            data={logResult}
            noDataMessage="Didn't have matching data."
            showTotalResult={true}
          />
        </SResultContainer>
      )}
    </SContainer>
  );
};
export default Home;
const SBackDrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
`;
const SResultContainer = styled.div`
  padding: 0 70px;
  margin-top: 20px;
`;

const SContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;
`;
const SGridContainer = styled.div`
  display: grid;
  grid-template-columns: 8% 41% 10% 41%;
  padding: 0px 20px 0px 0px;
  grid-gap: 20px;
`;
const SSeachSection = styled.div`
  margin-top: 20px;
  padding: 0 60px;
  width: 85%;
`;
const SGripInput = styled.div`
  width: 100%;
`;
const SGrid = styled.div`
  font-weight: 500;
  text-align: end;
  padding: 10px 0;
`;
