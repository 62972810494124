import { IAdminField } from "../interfaces/admin-interface";

export const adminFieldLeft: IAdminField[] = [
  // {
  //   fieldName: "datastoreCredential",
  //   fieldLabel: "Data Store Credential*",
  //   placeholder: "No selected file",
  //   type: "file",
  //   suggestion: [
  //     "Suggestion: <type of credential>-credential-<company name>.json",
  //     "Example: datastore-credential-gopomelo.json",
  //   ],
  //   side: "left",
  //   alert: "",
  // },
  // {
  //   fieldName: "gwsCredential",
  //   fieldLabel: "GWS Credential*",
  //   placeholder: "No selected file",
  //   type: "file",
  //   suggestion: [
  //     "Suggestion: <type of credential>-credential-<company name>.json",
  //     "Example: gws-credential-gopomelo.json",
  //   ],
  //   side: "left",
  //   alert: "",
  // },
  {
    fieldName: "companyName",
    fieldLabel: "Company Name*",
    placeholder: "Enter company name",
    type: "input",
    suggestion: ["* The company name should be uppercase and _."],
    side: "left",
    alert: ["* Can not use special character except _ in company name."],
  },
  {
    fieldName: "domainName",
    fieldLabel: "Domain Name*",
    placeholder: "example.com",
    type: "input",
    suggestion: [],
    side: "left",
    alert: ["*Invalid Domain Name Format."],
  },
  {
    fieldName: "users",
    fieldLabel: "Admin Account*",
    placeholder: "example@company.com",
    type: "multiInput",
    suggestion: ["*Account domain must be the same with Domain name."],
    side: "left",
    alert: [
      "*Invalid Account Format.Account domain must be the same with Domain name.",
      "*Account domain must be the same with Domain name.",
    ],
  },
  {
    fieldName: "expiryDate",
    fieldLabel: "Expiry Date",
    placeholder: new Date(),
    type: "date",
    suggestion: [],
    side: "left",
    alert: [],
  },
];
export const adminFieldRight: IAdminField[] = [
  // {
  //   fieldName: "expiryDate",
  //   fieldLabel: "Expiry Date",
  //   placeholder: new Date(),
  //   type: "date",
  //   suggestion: [],
  //   side: "right",
  //   alert: "",
  // },
];
