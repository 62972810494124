import React, { useState, createContext } from "react";
import { IUser } from "../interfaces/user-interface";

interface Props {
  children: React.ReactNode;
}
interface IUserContext {
  user: IUser | undefined;
  setUser: Function;
}
export const UserContext = createContext<IUserContext>({
  user: undefined,
  setUser: () => {},
});

const UserContextProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState(undefined);
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
