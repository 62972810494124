import { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import { ILogData } from "../interfaces/log-data-interface";
import { ReactComponent as PrevArrow } from "../images/prev-arrow-icon.svg";
import { ReactComponent as NextArrow } from "../images/next-arrow-icon.svg";
import { IAdmin } from "../interfaces/admin-interface";
import DropdownList from "./Dropdown/DropdownList";
import { DropdownContext } from "../contexts/DropdownContext";

interface IPagination {
  data: ILogData[] | IAdmin[];
  setCurrentData: Function;
}
const Pagination = ({ data, setCurrentData }: IPagination) => {
  const { dropdownRef, setDropdownRef } = useContext(DropdownContext);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    if (!dropdownIsOpen) {
      setDropdownRef(null);
    } else {
      setDropdownRef("dropdown");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownIsOpen]);
  useEffect(() => {
    if (!dropdownRef) {
      setDropdownIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentData(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, itemsPerPage, data]);
  const handlePageClick = (event: any) => {
    setSelectedPage(event.selected);
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <SContainer>
      <SItemPerPageSelectorContainer>
        Item per page:
        <SSelector onClick={() => setDropdownIsOpen(true)}>
          {itemsPerPage}
        </SSelector>
        <DropdownList
          dropdownIsOpen={dropdownIsOpen}
          choices={[
            { label: 10, icon: undefined },
            { label: 20, icon: undefined },
            { label: 50, icon: undefined },
            { label: 100, icon: undefined },
          ]}
          dropdownSelected={itemsPerPage}
          onclick={(e: number) => {
            setItemsPerPage(e);
            setDropdownIsOpen(false);
          }}
          setDropdownIsOpen={setDropdownIsOpen}
          css={{
            margin: "-185px 0px 0px 100px",
            width: "fit-content",
          }}
        />
      </SItemPerPageSelectorContainer>
      {pageCount > 1 && (
        <SReactPaginate
          breakLabel="..."
          nextLabel={
            <SButton className={selectedPage + 1 === pageCount ? "grey" : ""}>
              Next <NextArrow />
            </SButton>
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={
            <SButton className={selectedPage === 0 ? "grey" : ""}>
              <PrevArrow /> Previous
            </SButton>
          }
        />
      )}
    </SContainer>
  );
};
export default Pagination;
const SButton = styled.div`
  &.grey {
    cursor: not-allowed;
    color: #b6b6b6;
    & > svg > path {
      fill: #b6b6b6;
    }
  }
`;
const SSelector = styled.div`
  cursor: pointer;
  margin-left: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  padding: 0px 10px;
`;
const SItemPerPageSelectorContainer = styled.div`
  display: flex;
  position: relative;
  font-style: italic;
`;
const SContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const SReactPaginate = styled(ReactPaginate)`
  display: flex;
  list-style-type: none;
  font-style: italic;
  & > li {
    cursor: pointer;
    margin-right: 20px;
    &.selected {
      padding: 0 10px;
      background: rgba(60, 127, 236, 0.1);
      border-radius: 2px;
    }
  }
`;
