export const sortDate = (data: any[], key: string) => {
  let result = data.sort((a, b) => {
    const dateB = new Date(new Date(b[key]).setHours(0, 0, 0, 0));
    const dateA = new Date(new Date(a[key]).setHours(0, 0, 0, 0));
    return new Date(dateA).getDate() - new Date(dateB).getDate();
  });
  result = result.sort((a, b) => {
    const dateB = new Date(new Date(b[key]).setHours(0, 0, 0, 0));
    const dateA = new Date(new Date(a[key]).setHours(0, 0, 0, 0));
    return new Date(dateA).getMonth() - new Date(dateB).getMonth();
  });
  result = result.sort((a, b) => {
    const dateB = new Date(new Date(b[key]).setHours(0, 0, 0, 0));
    const dateA = new Date(new Date(a[key]).setHours(0, 0, 0, 0));
    return new Date(dateA).getFullYear() - new Date(dateB).getFullYear();
  });
  return result;
};
