import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Kabab } from "../images/kabab-icon.svg";
import { ILogData } from "../interfaces/log-data-interface";
import { IAdmin } from "../interfaces/admin-interface";
import Pagination from "./Pagination";
import DropdownList from "./Dropdown/DropdownList";
import { AdminContext } from "../contexts/AdminContext";
import { adminApi } from "../apis/admin-api";
import editIcon from "../images/edit-icon.png";
import mailIcon from "../images/mail.png";
import userIcon from "../images/user.png";
import deleteIcon from "../images/delete-icon.png";
import { LoadingContext } from "../contexts/LoadingContext";
import moment from "moment-timezone";
import "react-popper-tooltip/dist/styles.css";

interface ISearchResultTable {
  data: ILogData[] | IAdmin[];
  headers: string[];
  resultOrder: string[];
  noDataMessage: string;
  showTotalResult?: boolean;
  handleGetData?: Function;
  setKbab?: Function;
}
const ResultTable = ({
  data,
  headers,
  resultOrder,
  noDataMessage,
  setKbab,
  handleGetData,
  showTotalResult = false,
}: ISearchResultTable) => {
  const { setIsLoading } = useContext(LoadingContext);
  const { setOpenAdminPopup } = useContext(AdminContext);
  const [showingData, setShowingData] = useState<any[] | undefined>(undefined);
  const [indexOpen, setIndexOpen] = useState<number | undefined>(undefined);
  const [action, setAction] = useState<string | undefined>(undefined);
  const [actionSelected, setActionSelected] = useState<
    | {
        user: string;
        company: string;
        isActive: boolean;
      }
    | undefined
  >(undefined);
  const [onHover, setOnHover] = useState<number | undefined>(undefined);
  const handleDeleteCompany = async () => {
    if (!handleGetData) return;
    if (indexOpen !== undefined && showingData !== undefined) {
      setIsLoading({
        status: "inprogress",
        label: "Deleting company details...",
      });
      const res = await adminApi.postDelete(showingData[indexOpen].id);
      await handleGetData();
      if (res) {
        setIsLoading({
          status: "complete",
          label: "Company has been deleted.",
        });
      } else {
        setIsLoading({
          status: "fail",
          label: "Your company could not be deleted.",
        });
      }
    }
  };
  useEffect(() => {
    if (!action) return;
    if (action === "Edit") {
      setOpenAdminPopup("edit");
    } else if (action === "Delete") {
      handleDeleteCompany();
    } else if (action === "Update Users") {
      if (actionSelected?.isActive) {
        adminApi.updateUser(
          actionSelected?.company || "",
          actionSelected?.user || ""
        );
      }
    } else if (action === "Update Mail 30 Days") {
      if (actionSelected?.isActive) {
        adminApi.updateMail30Days(
          actionSelected?.company || "",
          actionSelected?.user || ""
        );
      }
    }
    setIndexOpen(undefined);
    setAction(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);
  return (
    <SContainer>
      {data.length ? (
        <STable>
          <table>
            <thead>
              <STr>
                {headers.map((e, q) => {
                  return (
                    <STd key={q} className="header">
                      {e}
                    </STd>
                  );
                })}
              </STr>
            </thead>
            <tbody>
              {showingData &&
                showingData.map((e: any, i) => {
                  return (
                    <STr key={i}>
                      {resultOrder.map((el: any, j) => {
                        return (
                          <STd
                            key={j}
                            className={el === "..." ? "clickable" : ""}
                            onClick={() => {
                              el === "..." &&
                                setIndexOpen(i === indexOpen ? undefined : i);
                            }}
                          >
                            {el === "..." ? (
                              <SKbabContainer>
                                <Kabab />
                                <DropdownList
                                  dropdownIsOpen={i === indexOpen}
                                  choices={[
                                    { label: "Edit", icon: editIcon },
                                    { label: "Delete", icon: deleteIcon },
                                    { label: "Update Users", icon: userIcon },
                                    {
                                      label: "Update Mail 30 Days",
                                      icon: mailIcon,
                                    },
                                  ]}
                                  dropdownSelected={""}
                                  onclick={(event: string) => {
                                    setActionSelected({
                                      user: `${e.users[0].email}`,
                                      company: `${e.companyName}`,
                                      isActive:
                                        new Date(e.expiryDate) >
                                        new Date(
                                          new Date().setHours(0, 0, 0, 0)
                                        )
                                          ? true
                                          : false,
                                    });
                                    setAction(event);
                                    setKbab && setKbab(e);
                                  }}
                                  css={{
                                    margin: "0 0 0 0",
                                    width: "fit-content",
                                  }}
                                  setDropdownIsOpen={(e: boolean) => {
                                    e === false && setIndexOpen(undefined);
                                  }}
                                />
                              </SKbabContainer>
                            ) : el === "no" ? (
                              i + 1
                            ) : el === "status" ? (
                              new Date(e.expiryDate) >
                              new Date(new Date().setHours(0, 0, 0, 0)) ? (
                                <SStatus className="active">Active</SStatus>
                              ) : (
                                <SStatus className="expired">Expired</SStatus>
                              )
                            ) : el === "expiryDate" ? (
                              moment(e[el]).tz("Asia/Bangkok").format("LL")
                            ) : el === "users" ? (
                              <SNumberContainer>
                                <div>{e[el][0]?.email}</div>
                                {e[el].length - 1 > 0 ? (
                                  <div style={{ position: "relative" }}>
                                    <div
                                      onMouseOver={() => setOnHover(i)}
                                      onMouseOut={() => setOnHover(undefined)}
                                      style={{
                                        color: "white",
                                        backgroundColor: "#3C7FEC",
                                        width: "20px",
                                        height: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "100%",
                                        marginLeft: "5px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {e[el].length - 1}
                                    </div>
                                    {onHover === i && (
                                      <div
                                        style={{
                                          position: "absolute",
                                          background: " #EBF2FD",
                                          boxShadow:
                                            "0px 8px 24px rgba(0, 0, 0, 0.1)",
                                          borderRadius: "4px",
                                          marginLeft: "20px",
                                          width: "fit-content",
                                          zIndex: "50",
                                          padding: "10px 30px",
                                        }}
                                      >
                                        {e[el].map((z: any, i: number) => {
                                          if (i !== 0) {
                                            return (
                                              <div
                                                style={{
                                                  height: "40px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                                key={i}
                                              >
                                                {z.email}
                                              </div>
                                            );
                                          }
                                          return "";
                                        })}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </SNumberContainer>
                            ) : (
                              e[el]
                            )}
                          </STd>
                        );
                      })}
                    </STr>
                  );
                })}
            </tbody>
          </table>
        </STable>
      ) : (
        <SLabel>{noDataMessage}</SLabel>
      )}
      <SFooter className={showTotalResult ? "showTotalResult" : ""}>
        {showTotalResult && (
          <STotal>{`Match with ${data.length} result.`}</STotal>
        )}
        <Pagination data={data} setCurrentData={setShowingData} />
      </SFooter>
    </SContainer>
  );
};
export default ResultTable;
const SNumberContainer = styled.div`
  display: flex;
`;
const STotal = styled.div`
  font-size: 13px;
  font-style: italic;
  margin-left: 20px;
`;
const SFooter = styled.div`
  display: flex;
  align-items: center;
  &.showTotalResult {
    justify-content: space-between;
  }
  justify-content: flex-end;
`;
const SContainer = styled.div`
  height: 100%;
`;
const SStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  width: 80px;
  height: 25px;
  font-weight: 500;
  &.active {
    background-color: rgba(80, 189, 137, 0.1);
    color: #50bd89;
  }
  &.expired {
    background-color: rgba(184, 55, 55, 0.1);
    color: #b83737;
  }
`;
const SKbabContainer = styled.div`
  position: relative;
  /* width: 100px; */
  display: flex;
  justify-content: flex-end;
`;
const SLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-weight: 500;
`;
const STable = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  font-size: 15px;
  height: 100%;
  & > table {
    border-spacing: 20px;
    width: 100%;
  }
`;
const STd = styled.td`
  white-space: nowrap;
  &.header {
    color: rgba(0, 0, 0, 0.3);
    text-align: start;
    border-bottom: 1px solid rgba(197, 197, 197, 0.3);
  }
  &.clickable {
    cursor: pointer;
    width: 20px;
  }
`;
const STr = styled.tr``;
