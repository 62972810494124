import styled from "styled-components";

interface IButton {
  children: any;
  onclick: Function;
  disable?: any;
  addonCss?: any;
}
const Button = ({ children, onclick, addonCss, disable = false }: IButton) => {
  return (
    <SButton
      style={addonCss}
      className={disable ? "disable" : ""}
      onClick={() => !disable && onclick()}
    >
      {children}
    </SButton>
  );
};
export default Button;
const SButton = styled.div`
  &.disable {
    cursor: not-allowed;
  }
  color: black;
  background-color: white;
  width: fit-content;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    height: 20px;
  }
`;
