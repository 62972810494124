import { ISeachQuery } from "../interfaces/search-query-interface";
import GoWorkLogInstance from "./instance";
const get = async (
  token: string,
  company: string,
  searchQuery: ISeachQuery,
  by: string
) => {
  try {
    token = `Bearer ${token}`;
    const logDataResult = await GoWorkLogInstance.get(
      `/datastore/gmail?company=${company}&startDate=${searchQuery.startDate}&endDate=${searchQuery.endDate}&sender=${searchQuery.Sender}&eventTargetIPAddress=${searchQuery["IP address"]}&recipientAddress=${searchQuery.Recipient}&subject=${searchQuery.Subject}&messageId=${searchQuery["Message ID"]}&by=${by}`,
      {
        headers: { authorization: token },
      }
    );
    return logDataResult.data;
  } catch (err) {}
};
export const logData = { get };
