import { ISeachQuery } from "../interfaces/search-query-interface";
import GoWorkLogInstance from "./instance";
const generate = async (
  token: string,
  company: string,
  searchQuery: ISeachQuery
) => {
  try {
    token = `Bearer ${token}`;
    const logDataResult = await GoWorkLogInstance.get(
      `/datastore/generate/googlesheet?company=${company}&startDate=${searchQuery.startDate}&endDate=${searchQuery.endDate}&sender=${searchQuery.Sender}&eventTargetIPAddress=${searchQuery["IP address"]}&recipientAddress=${searchQuery.Recipient}&subject=${searchQuery.Subject}&messageId=${searchQuery["Message ID"]}`,
      {
        headers: { authorization: token },
      }
    );
    console.log("logDataResultlogDataResult :::", logDataResult);
    return logDataResult.data.spreadSheetUrl || "";
  } catch (err) {}
};
export const googleSheets = { generate };
