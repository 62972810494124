import { IField } from "../interfaces/field-interface";

export const defaultFields: IField[] = [
  {
    label: "Date",
    type: "calendar",
    placeholder: "",
    choices: [
      { label: "Since Yesterday", icon: undefined },
      { label: "Last 7 days", icon: undefined },
      { label: "Last 30 days", icon: undefined },
      { label: "Last 60 days", icon: undefined },
      { label: "Last 90 days", icon: undefined },
      { label: "Custom Date Range", icon: undefined },
    ],
    row: 1,
    span: "2 / span 1",
  },
  {
    label: "Sender",
    type: "input",
    placeholder: "Enter full or partial sender email address",
    choices: [],
    row: 2,
    span: "2 / span 1",
  },

  {
    label: "Recipient",
    type: "input",
    placeholder: "Enter full or partial recipient email address",
    choices: [],
    row: 3,
    span: "2 / span 1",
  },
  {
    label: "IP address",
    type: "input",
    placeholder: "Enter full or partial IP address",
    choices: [],
    row: 3,
    span: "4 / span 1",
  },
  {
    label: "Subject",
    type: "input",
    placeholder: "Enter subject",
    choices: [],
    row: 4,
    span: "2 / span 3",
  },
  {
    label: "Message ID",
    type: "input",
    placeholder: "Enter message ID",
    choices: [],
    row: 5,
    span: "2 / span 3",
  },
];
