import styled from "styled-components";

interface ITableBar {
  children: React.ReactNode;
  label: string;
}
const TableBar = ({ children, label }: ITableBar) => {
  return (
    <SContainer>
      <SLabel>{label}</SLabel>
      {children}
    </SContainer>
  );
};
export default TableBar;
const SLabel = styled.div`
  color: #3c7fec;
  margin-left: 50px;
  font-weight: 500;
`;
const SContainer = styled.div`
  width: 100%;
  background: rgba(60, 127, 236, 0.1);
  border-radius: 6px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
