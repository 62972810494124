import styled from "styled-components";
import Button from "./Button";
interface IButton {
  label: string;
  onClick: Function;
}
interface IConfirmPopup {
  header: string;
  content: string;
  confirm?: IButton;
  cancel?: IButton;
}
const ConfirmPopup = ({ header, content, confirm, cancel }: IConfirmPopup) => {
  return (
    <SContainer>
      <SContentContainer>
        <SHeader>{header}</SHeader>
        <SContent>
          {content.split(".").map((e, i) => {
            return (
              <div>
                {e}
                {i + 1 !== content.split(".").length ? "." : ""}
              </div>
            );
          })}
        </SContent>
        <SButtonContainer>
          {cancel ? (
            <Button
              onclick={cancel.onClick}
              addonCss={{
                border: "1px solid #E4E4E4",
                padding: "10px 35px",
                marginRight: "10px",
              }}
            >
              {cancel.label}
            </Button>
          ) : (
            ""
          )}
          {confirm ? (
            <Button
              onclick={confirm.onClick}
              addonCss={{
                color: "white",
                backgroundColor: "#3C7FEC",
                padding: "10px 35px",
                marginLeft: "10px",
              }}
            >
              {confirm.label}
            </Button>
          ) : (
            ""
          )}
        </SButtonContainer>
      </SContentContainer>
    </SContainer>
  );
};
export default ConfirmPopup;

const SContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20;
`;
const SContentContainer = styled.div`
  background-color: white;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SHeader = styled.div`
  border-bottom: 1px solid #e4e4e4;
  padding: 10px 10px;
  font-weight: 500;
  width: 94%;
  font-size: 20px;
`;
const SButtonContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;
const SContent = styled.div`
  height: 120px;
  width: 100%;
  padding: 0px 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
