import GoWorkLogInstance from "./instance";
const validation = async (token: string, admin: boolean) => {
  token = `Bearer ${token}`;
  const tokenValidation = await GoWorkLogInstance.get(
    `/token/validation?isAdmin=${admin ? "admin" : ""}`,
    {
      headers: { authorization: token },
    }
  );
  return tokenValidation.data;
};
export const tokenApi = { validation };
