import { CSVLink } from "react-csv";
import styled from "styled-components";
import { ILogData } from "../interfaces/log-data-interface";

interface IDownloadCsv {
  data: ILogData[];
}
const DownloadCsv = ({ data }: IDownloadCsv) => {
  const handlePrepareDate = () => {
    const csvFormat = [];
    csvFormat.push([
      "Message ID",
      "Date",
      "Sender",
      "Sender Message size",
      "Subject",
      "Direction",
      "Attachments",
      "Recipient address",
      "Event target IP address",
    ]);
    data.forEach((e) => {
      csvFormat.push([
        e.messageId,
        e.date,
        e.sender,
        e.senderMessageSize,
        e.subject,
        e.direction,
        e.attachments,
        e.recipientAddress,
        e.eventTargetIPAddress,
      ]);
    });
    return csvFormat;
  };
  const today = new Date();
  return (
    <SCSVLink
      data={handlePrepareDate()}
      filename={`${"Email Log"} ${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()} : ${today.getTime()} `}
    >
      Download as .csv
    </SCSVLink>
  );
};
export default DownloadCsv;
const SCSVLink = styled(CSVLink)`
  font-size: 14px;
  text-decoration: none;
  color: black;
`;
