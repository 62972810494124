import moment from "moment";

export const calculateDate = (condition: string, setDate: Function) => {
  const endDate = moment();
  const startDate = moment();
  switch (condition) {
    case "Since Yesterday":
      setDate(
        moment(startDate).add(-1, "days").set({ hour: 0, minute: 0 }),
        moment(endDate).set({ hour: 0, minute: 0 })
      );
      return;
    case "Last 7 days":
      setDate(
        moment(startDate).add(-7, "days").set({ hour: 0, minute: 0 }),
        moment(endDate).set({ hour: 0, minute: 0 })
      );
      return;
    case "Last 30 days":
      setDate(
        moment(startDate).add(-30, "days").set({ hour: 0, minute: 0 }),
        moment(endDate).set({ hour: 0, minute: 0 })
      );
      return;
    case "Last 60 days":
      setDate(
        moment(startDate).add(-60, "days").set({ hour: 0, minute: 0 }),
        moment(endDate).set({ hour: 0, minute: 0 })
      );
      return;
    case "Last 90 days":
      setDate(
        moment(startDate).add(-90, "days").set({ hour: 0, minute: 0 }),
        moment(endDate).set({ hour: 0, minute: 0 })
      );
      return;
  }
};
