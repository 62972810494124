import React, { createContext, useState } from "react";
interface Props {
  children: React.ReactNode;
}
interface IAdminContext {
  openAdminPopup: "create" | "edit" | undefined;
  setOpenAdminPopup: Function;
}
export const AdminContext = createContext<IAdminContext>({
  openAdminPopup: undefined,
  setOpenAdminPopup: () => {},
});

const AdminContextProvider: React.FC<Props> = ({ children }) => {
  const [openAdminPopup, setOpenAdminPopup] = useState<
    "create" | "edit" | undefined
  >(undefined);
  return (
    <AdminContext.Provider value={{ openAdminPopup, setOpenAdminPopup }}>
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContextProvider;
