import React, { useContext, useState } from "react";
import styled from "styled-components";
import { UserContext } from "../contexts/UserContext";
import DropdownList from "./Dropdown/DropdownList";

interface ITitleBar {
  title: string;
  userName?: string;
  userImageUrl?: string;
}
const TitleBar = ({ title, userName, userImageUrl }: ITitleBar) => {
  const { setUser } = useContext(UserContext);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  return (
    <SContainer>
      <SHeader>{title}</SHeader>
      <SUserContainer>
        <SLabel>{userName}</SLabel>
        {userImageUrl && (
          <SImg
            onClick={() => {
              setDropdownIsOpen(!dropdownIsOpen);
            }}
            src={userImageUrl}
          />
        )}
        <DropdownList
          setDropdownIsOpen={setDropdownIsOpen}
          dropdownIsOpen={dropdownIsOpen}
          choices={[{ label: "Sign Out", icon: undefined }]}
          dropdownSelected={""}
          onclick={() => {
            setUser(undefined);
          }}
          css={{ margin: "100px 0px 0px 50px", width: "150px" }}
        />
      </SUserContainer>
    </SContainer>
  );
};
export default TitleBar;
const SContainer = styled.div`
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  justify-content: space-between;
`;
const SUserContainer = styled.div`
  display: flex;
  align-items: center;
`;
const SHeader = styled.div`
  font-weight: 800;
  color: #3c7fec;
  font-size: 27px;
`;
const SLabel = styled.div`
  font-weight: 500;
`;
const SImg = styled.img`
  cursor: pointer;
  width: 50px;
  border-radius: 50%;
  margin-left: 20px;
  border: 2px solid rgba(228, 228, 228, 0.5);
`;
