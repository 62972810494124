import { useState } from "react";
import styled from "styled-components";
import { calculateDate } from "../../utils/calculate-date";
import DatePicker from "../DatePicker";
import Dropdown from "./Dropdown";
interface IDropdownGrid {
  data: any;
  choices: { label: string; icon: any | undefined }[];
  onSelect: Function;
}
const DropdownGrid = ({ data, choices, onSelect }: IDropdownGrid) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [dropdownSelected, setDropdownSelected] = useState(choices[0].label);

  return (
    <SContainer>
      <Dropdown
        dropdownSelected={dropdownSelected}
        dropdownIsOpen={dropdownIsOpen}
        setDropdownIsOpen={setDropdownIsOpen}
        choices={choices}
        onclick={(e: string) => {
          calculateDate(e, onSelect);
          setDropdownSelected(e);
          setDropdownIsOpen(false);
        }}
        css={dropdownSelected === "Custom Date Range" ? { width: "42%" } : {}}
      />
      {dropdownSelected === "Custom Date Range" && (
        <DatePicker
          startDate={data.startDate}
          endDate={data.endDate}
          onSelect={onSelect}
        />
      )}
    </SContainer>
  );
};
export default DropdownGrid;

const SContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;
