import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import UserContextProvider from "./contexts/UserContext";
import LoadingContextProvider from "./contexts/LoadingContext";
import DropdownContextProvider from "./contexts/DropdownContext";
import AdminContextProvider from "./contexts/AdminContext";
import Admin from "./pages/Admin";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Routes>
        <Route
          path="/"
          element={
            <UserContextProvider>
              <DropdownContextProvider>
                <LoadingContextProvider>
                  <App />
                </LoadingContextProvider>
              </DropdownContextProvider>
            </UserContextProvider>
          }
        />
        <Route
          path="admin"
          element={
            <UserContextProvider>
              <AdminContextProvider>
                <DropdownContextProvider>
                  <LoadingContextProvider>
                    <Admin />
                  </LoadingContextProvider>
                </DropdownContextProvider>
              </AdminContextProvider>
            </UserContextProvider>
          }
        />
      </Routes>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
