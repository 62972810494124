import styled from "styled-components";
import Button from "./Button";
import DownLoadIcon from "../images/download-icon.png";
import { useContext, useEffect, useState } from "react";
import { ILogData } from "../interfaces/log-data-interface";
import DownloadCsv from "../services/DownloadCsv";
import { ReactComponent as HistoryIcon } from "../images/history-icon.svg";
import { googleSheets } from "../apis/google-sheets-api";
import { ISeachQuery } from "../interfaces/search-query-interface";
import { LoadingContext } from "../contexts/LoadingContext";
import { UserContext } from "../contexts/UserContext";
import { DropdownContext } from "../contexts/DropdownContext";
import TableBar from "./TableBar";
import DropdownList from "./Dropdown/DropdownList";
interface ISeachResultBar {
  data: ILogData[];
  searchQuery: ISeachQuery;
}

const SeachResultBar = ({ data, searchQuery }: ISeachResultBar) => {
  const { user } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { dropdownRef, setDropdownRef } = useContext(DropdownContext);
  const [historyIsOpen, setHistoryIsOpen] = useState(false);
  const [history, setHistory] = useState<
    [] | { label: any; icon: any | undefined }[]
  >([]);
  useEffect(() => {
    if (!isDropdownOpen) {
      setDropdownRef(null);
    } else {
      setDropdownRef("dropdown");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownOpen]);
  useEffect(() => {
    if (!dropdownRef) {
      setIsDropdownOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);
  const handleGenerateGoogleSheet = async () => {
    if (!user) return;
    setIsDropdownOpen(false);
    setIsLoading({
      status: "inprogress",
      label: "Google sheets generating...",
    });
    const googleSheetUrl = await googleSheets.generate(
      user.idToken,
      user.company,
      searchQuery
    );
    console.log("GOOGLE SHEET URL ::::", googleSheetUrl);
    setHistory([
      {
        label: (
          <div
            style={{ width: "300px" }}
            onClick={() => {
              if (googleSheetUrl.length > 1 && googleSheetUrl[0].name) {
                window.open(
                  `https://docs.google.com/spreadsheets/u/0/?tgif=d&q=${googleSheetUrl[0].name}`
                );
              } else if (googleSheetUrl.length === 1) {
                window.open(googleSheetUrl[0].url);
              }
            }}
          >
            <div
              style={{
                width: "300px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                fontSize: "10px",
              }}
            >
              Downloaded :{" "}
              {googleSheetUrl[0]?.name ? googleSheetUrl[0]?.name : ""}
            </div>
          </div>
        ),
        icon: undefined,
      },
      ...history,
    ]);
    setIsLoading({
      status: googleSheetUrl ? "complete" : "fail",
      label: (
        <SLink
          onClick={() => {
            if (googleSheetUrl.length > 1 && googleSheetUrl[0].name) {
              window.open(
                `https://docs.google.com/spreadsheets/u/0/?tgif=d&q=${googleSheetUrl[0].name}`
              );
            } else if (googleSheetUrl.length === 1) {
              window.open(googleSheetUrl[0].url);
            }
          }}
        >
          {googleSheetUrl
            ? "Click here or check your drive to see your Google sheets."
            : "Can not generate google sheet link"}
        </SLink>
      ),
    });
  };
  return (
    <TableBar label={"Search Result"}>
      {data.length ? (
        <SButtonContainer>
          {history.length ? (
            <SHistoryButton
              onClick={() => {
                setHistoryIsOpen(true);
              }}
            >
              <HistoryIcon />
            </SHistoryButton>
          ) : (
            ""
          )}
          <Button
            onclick={() => {
              setIsDropdownOpen(!isDropdownOpen);
            }}
            addonCss={{
              color: "#3C7FEC",
              fontWeight: "500",
            }}
          >
            <img alt="" src={DownLoadIcon}></img>
            <SLabelButton>Download</SLabelButton>
          </Button>

          <DropdownList
            dropdownIsOpen={historyIsOpen}
            choices={history}
            dropdownSelected=""
            onclick={(e: string) => {}}
            setDropdownIsOpen={setHistoryIsOpen}
            css={{
              margin: "0px 0px -90px -300px",
              width: "fit-content",
            }}
          />

          {isDropdownOpen && (
            <SDropdownList>
              <SChoice onClick={() => setIsDropdownOpen(false)}>
                <DownloadCsv data={data} />
              </SChoice>
              <SChoice onClick={() => handleGenerateGoogleSheet()}>
                Export to Google Sheets
              </SChoice>
            </SDropdownList>
          )}
        </SButtonContainer>
      ) : (
        ""
      )}
    </TableBar>
  );
};
export default SeachResultBar;
const SHistoryButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 100%;
  cursor: pointer;
`;
const SLink = styled.div`
  cursor: pointer;
`;
const SChoice = styled.div`
  font-size: 14px;
  color: black;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: rgba(60, 127, 236, 0.1);
  }
`;
const SDropdownList = styled.div`
  position: absolute;
  width: 210px;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-left: -60px;
  margin-top: 130px;
`;
const SLabelButton = styled.div`
  margin-left: 10px;
`;

const SButtonContainer = styled.div`
  margin-right: 50px;
  position: relative;
  display: flex;
  align-items: center;
`;
