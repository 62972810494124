import styled from "styled-components";

interface IInput {
  placeholder: string;
  onChange: Function;
  addonCss?: any;
  disabled?: boolean;
  value?: any;
}
const Input = ({
  placeholder,
  onChange,
  value,
  addonCss,
  disabled = false,
}: IInput) => {
  return (
    <SContainer className={disabled ? "disabled" : ""} style={addonCss}>
      <SInput
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={(e: any) => {
          onChange(e.target.value);
        }}
      />
    </SContainer>
  );
};
export default Input;
const SContainer = styled.div`
  width: 100%;
  border: 1px solid #e4e4e4;
  height: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &.disabled {
    background-color: rgba(239, 239, 239, 0.8);
  }
`;
const SInput = styled.input`
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: rgb(118, 118, 118);
    font-family: "Poppins", sans-serif;
  }
  border: none;
  width: calc(100% - 20px);
`;
