import { useContext } from "react";
import styled from "styled-components";
import { LoadingContext } from "../contexts/LoadingContext";
import { ReactComponent as InprogressIcon } from "../images/inprogress-icon.svg";
import { ReactComponent as FailIcon } from "../images/fail-icon.svg";
import { ReactComponent as CompleteIcon } from "../images/complete-icon.svg";
interface ISnackBar {
  addonCss?: any;
}
const SnackBar = ({ addonCss }: ISnackBar) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  return (
    <SContainer
      style={addonCss}
      className={`${isLoading ? "movein" : "moveout"} ${isLoading?.status}`}
    >
      {isLoading?.status === "inprogress" ? (
        <InprogressIcon />
      ) : isLoading?.status === "fail" ? (
        <FailIcon />
      ) : (
        <CompleteIcon />
      )}
      <SLabel>{isLoading?.label}</SLabel>
      <SCloseIcon onClick={() => setIsLoading(undefined)}>X</SCloseIcon>
    </SContainer>
  );
};
export default SnackBar;
const SContainer = styled.div`
  &.complete {
    background: rgba(76, 201, 141, 0.1);
  }
  &.inprogress {
    background: rgba(60, 127, 236, 0.1);
  }
  &.fail {
    background: rgba(184, 55, 55, 0.1);
  }
  box-shadow: 0px 4px 12px rgba(60, 127, 236, 0.1);
  border-radius: 4px;
  position: absolute;
  align-items: center;
  display: none;
  padding: 10px 20px;
  &.movein {
    display: flex;
    @keyframes movein {
      from {
        margin-right: -200px;
      }
      to {
        margin-right: 0px;
      }
    }
    animation-name: movein;
    animation-duration: 1s;
    right: 20px;
    top: 90px;
  }
`;
const SLabel = styled.div`
  margin: 0px 20px;
`;
const SCloseIcon = styled.div`
  cursor: pointer;
`;
