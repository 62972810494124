import GoWorkLogInstance from "./instance";
const getAllDetail = async () => {
  const result = await GoWorkLogInstance.get("/datastore/admin");
  return result.data;
};
const postSave = async (req: any, oldId?: string) => {
  const result = await GoWorkLogInstance.post(
    `/datastore/entity/save?kind=information&id=${req.id}&namespace=Company&oldId=${oldId}`,
    req
  );
  return result.data;
};
const postDelete = async (id: string) => {
  const result = await GoWorkLogInstance.post(
    `/datastore/entity/delete?kind=information&id=${id}&namespace=Company`
  );
  return result.data;
};
const updateUser = async (company: string, email: string) => {
  const result = await GoWorkLogInstance.get(
    `/datastore/autoUpdateUser?company=${company}&email=${email}&count=0`
  );
  return result.data;
};
const updateMail30Days = async (company: string, email: string) => {
  const result = await GoWorkLogInstance.get(
    `/datastore/autoUpdateMail?company=${company}&email=${email}&count=0&dayAgo=30`
  );
  return result.data;
};
export const adminApi = {
  getAllDetail,
  postSave,
  postDelete,
  updateUser,
  updateMail30Days,
};
