import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { tokenApi } from "../apis/token-api";
import gopoLogo from "../images/gopo-logo.png";
import { ReactComponent as Loading } from "../images/loading.svg";
interface ILogin {
  setUserLogin: Function;
  admin?: boolean;
}
declare global {
  interface Window {
    google: any;
  }
}
declare const google: any;
const Login = ({ setUserLogin, admin = false }: ILogin) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<string>("");
  const [logedinMail, setLogedinMail] = useState("");
  const responseGoogleLoginSucceess = async (response: any) => {
    setIsLoading(true);
    const userData = JSON.parse(window.atob(response.credential.split(".")[1]));
    setLogedinMail(userData.email);
    console.log("login by: ", userData.email);
    let result = undefined;

    result = {
      email: userData.email,
      name: userData.name,
      givenName: userData.given_name,
      familyName: userData.family_name,
      imageUrl: userData.picture,
      idToken: response.credential,
      domain: userData.hd,
    };
    if (result) {
      try {
        let data = undefined;
        data = await tokenApi.validation(result.idToken, admin);
        if (admin) {
          setUserLogin({ ...result });
          setIsLoading(false);
        } else if (
          new Date(data.expiryDate) > new Date(new Date().setHours(0, 0, 0, 0))
        ) {
          setUserLogin({ ...result, company: data.companyName });
          setIsLoading(false);
        }
      } catch (err: any) {
        setAlert(err.response.data);
        setIsLoading(false);
        setUserLogin(undefined);
        response.disconnect();
      }
    }
  };
  useEffect(() => {
    if (!google) return;
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
      callback: (e: any) => responseGoogleLoginSucceess(e),
    });
    google.accounts.id.renderButton(document.getElementById("buttonDiv"), {
      theme: "outline",
      size: "large",
    });
    google.accounts.id.prompt((notification: any) => {
      console.log("on prompt notification", notification);
    });
    // eslint-disable-next-line
  }, [google]);
  return (
    <SContainer>
      <img src={gopoLogo} alt="" />
      <SLabel>
        {admin
          ? "Welcome to Administration Portal"
          : "Welcome to Email Log Search"}
      </SLabel>
      {isLoading && (
        <div>
          <Loading />
        </div>
      )}

      {!isLoading && (
        <div
          id="buttonDiv"
          data-client_id={`${process.env.REACT_APP_OAUTH_CLIENT_SECRET}`}
        ></div>
      )}
      {alert && `Login by ${logedinMail}. `}
      {alert && alert}
    </SContainer>
  );
};
export default Login;
const SLabel = styled.div`
  margin-bottom: 40px;
  margin-top: 20px;
`;
const SContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 15%;
  align-items: center;
`;
