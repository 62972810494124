import styled from "styled-components";
import DropdownList from "./DropdownList";

interface IDropdown {
  dropdownSelected: string;
  dropdownIsOpen: boolean;
  setDropdownIsOpen: Function;
  choices: { label: string; icon: any | undefined }[];
  onclick: Function;
  css?: any;
}
const Dropdown = ({
  dropdownSelected,
  dropdownIsOpen,
  setDropdownIsOpen,
  choices,
  onclick,
  css,
}: IDropdown) => {
  return (
    <SContainer onClick={() => setDropdownIsOpen(!dropdownIsOpen)} style={css}>
      <SDropdown>{dropdownSelected}</SDropdown>
      <DropdownList
        setDropdownIsOpen={setDropdownIsOpen}
        dropdownIsOpen={dropdownIsOpen}
        choices={choices}
        dropdownSelected={dropdownSelected}
        onclick={onclick}
      />
    </SContainer>
  );
};
export default Dropdown;
const SContainer = styled.div`
  height: 100%;
  width: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;
  cursor: pointer;
`;
const SDropdown = styled.div`
  color: #3c7fec;
`;
