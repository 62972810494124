import React, { createContext, useState } from "react";
interface Props {
  children: React.ReactNode;
}
interface IDropdownContext {
  dropdownRef: string | null;
  setDropdownRef: Function;
}
export const DropdownContext = createContext<IDropdownContext>({
  dropdownRef: null,
  setDropdownRef: () => {},
});

const DropdownContextProvider: React.FC<Props> = ({ children }) => {
  const [dropdownRef, setDropdownRef] = useState<string | null>(null);
  return (
    <DropdownContext.Provider value={{ dropdownRef, setDropdownRef }}>
      {children}
    </DropdownContext.Provider>
  );
};

export default DropdownContextProvider;
