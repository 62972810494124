import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { adminApi } from "../apis/admin-api";
import { AdminContext } from "../contexts/AdminContext";
import { LoadingContext } from "../contexts/LoadingContext";
import { IAdminField, ICompanyDetail } from "../interfaces/admin-interface";
import { adminFieldLeft, adminFieldRight } from "../sources/adminField";
import Button from "./Button";
import ConfirmPopup from "./ConfirmPopup";
import DatePicker from "./DatePicker";
import Input from "./Input";
import DeleteIcon from "../images/delete-icon.png";
import { ReactComponent as Loading } from "../images/loading.svg";

interface IAdminPopup {
  by: "create" | "edit";
  companyDetail?: ICompanyDetail | undefined;
  companiesName?: string[] | undefined;
  setCompanyDetail: Function;
  handleCreateAndEditCompany: Function;
}
const AdminPopup = ({
  by,
  companyDetail,
  setCompanyDetail,
  handleCreateAndEditCompany,
  companiesName,
}: IAdminPopup) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { setOpenAdminPopup } = useContext(AdminContext);
  const [isFieldComplete, setIsFieldComplete] = useState(false);
  const [fieldWrongFormats, setFieldWrongFormat] = useState<string[]>([]);
  const [confirmPopupIsOpen, setConfirmPopupIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState<any>({
    id: companyDetail?.id || "",
    users: companyDetail?.users.map((e: any) => e.email) || [""],
    companyName: companyDetail?.companyName || "",
    domainName: companyDetail?.domainName || "",
    expiryDate: companyDetail?.expiryDate || `${new Date()}`,
    deleted: [],
    edited: [],
  });
  const [adminAccount, setAdminAccount] = useState<string[]>(
    companyDetail?.users.map((e: any) => e.email) || [""]
  );
  useEffect(() => {
    const keys = Object.keys(value);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === "expiryDate" || keys[i] === "deleted") {
        continue;
      }
      if (!value[keys[i]]) {
        setIsFieldComplete(false);
        break;
      } else if (value[keys[i]][0] === "") {
        setIsFieldComplete(false);
        break;
      } else {
        setIsFieldComplete(true);
      }
    }
  }, [value.users, value.deleted, value.companyName, value.domainName, value]);
  const handleRenderField = (field: IAdminField, index: number) => {
    if (field.type === "date") {
      return (
        <SFieldContainer key={index}>
          <STopic>{field.fieldLabel}</STopic>
          <SInputContainer>
            <DatePicker
              startDate={value[field.fieldName]}
              onSelect={(e: string) =>
                setValue({ ...value, [field.fieldName]: e })
              }
              rangeOn={false}
              addonCss={{ width: "93%", height: "40px" }}
            />
          </SInputContainer>
          {fieldWrongFormats.length &&
          fieldWrongFormats.indexOf(field.fieldName) > -1
            ? field.alert.map((e, i) => {
                return <SAlert key={i}>{e}</SAlert>;
              })
            : field.suggestion.map((e, i) => {
                return <SSuggestion key={i}>{e}</SSuggestion>;
              })}
        </SFieldContainer>
      );
    } else if (field.type === "input") {
      return (
        <SFieldContainer key={index}>
          <STopic>{field.fieldLabel}</STopic>
          <SInputContainer>
            <Input
              addonCss={
                fieldWrongFormats.length &&
                fieldWrongFormats.indexOf(field.fieldName) > -1
                  ? { border: "solid 1px red" }
                  : {}
              }
              placeholder={field.placeholder}
              value={value[field.fieldName]}
              onChange={(e: string) => {
                if (field.fieldName === "companyName") {
                  setValue({
                    ...value,
                    [field.fieldName]: e.replace(" ", "-"),
                    id: value.companyName.toLowerCase().replace(" ", "-"),
                  });
                } else {
                  setValue({ ...value, [field.fieldName]: e });
                }
              }}
            />
          </SInputContainer>
          {fieldWrongFormats.length &&
          fieldWrongFormats.indexOf(field.fieldName) > -1
            ? field.alert.map((e, i) => {
                return <SAlert key={i}>{e}</SAlert>;
              })
            : field.suggestion.map((e, i) => {
                return <SSuggestion key={i}>{e}</SSuggestion>;
              })}
        </SFieldContainer>
      );
    } else if (field.type === "multiInput") {
      return (
        <SFieldContainer key={index}>
          <STopic>{field.fieldLabel}</STopic>

          {adminAccount.map((l, i) => {
            return (
              <>
                <SInputContainer key={i}>
                  <Input
                    addonCss={
                      fieldWrongFormats.length &&
                      fieldWrongFormats.indexOf(`${field.fieldName}${i}`) > -1
                        ? { border: "solid 1px red" }
                        : {}
                    }
                    placeholder={field.placeholder}
                    value={value.users[i]}
                    onChange={(e: string) => {
                      const result = adminAccount;
                      result[i] = e;
                      setAdminAccount(result);
                      if (companyDetail?.users[i]) {
                        setValue({
                          ...value,
                          [field.fieldName]: result,
                          edited: [
                            ...value.edited,
                            companyDetail?.users[i].email,
                          ].reduce(function (a, b) {
                            if (a.indexOf(b) < 0) a.push(b);
                            return a;
                          }, []),
                        });
                      } else {
                        setValue({ ...value, [field.fieldName]: result });
                      }
                    }}
                  />
                  {adminAccount.length !== 1 && (
                    <SIcon
                      onClick={() => {
                        const result = adminAccount;
                        const deleting = adminAccount[i];
                        setValue({
                          ...value,
                          [field.fieldName]: result,
                          deleted: [...value.deleted, deleting],
                        });
                        result.splice(i, 1);
                        setAdminAccount(result);
                      }}
                    >
                      <img src={DeleteIcon} alt="delete icon" />
                    </SIcon>
                  )}
                </SInputContainer>
                {fieldWrongFormats.length &&
                fieldWrongFormats.indexOf(`${field.fieldName}${i}`) > -1
                  ? field.alert.map((e, i) => {
                      return <SAlert key={i}>{e}</SAlert>;
                    })
                  : field.suggestion.map((e, i) => {
                      return <SSuggestion key={i}>{e}</SSuggestion>;
                    })}
              </>
            );
          })}
          <SAddDomain
            onClick={() => {
              setAdminAccount([...adminAccount, ""]);
            }}
          >
            + Add New
          </SAddDomain>
        </SFieldContainer>
      );
    }
  };

  const handleSave = async () => {
    if (!isFieldComplete) return;
    const keys = Object.keys(value);
    setFieldWrongFormat([]);
    const wrongFormat: string[] = [];

    keys.forEach((key: string) => {
      if (key === "users") {
        const check = value[key].filter((e: any) => e !== "");
        if (!check.length) {
          wrongFormat.push(`${key}0`);
        } else {
          value[key].forEach((e: string, i: number) => {
            const sp = e.split("@");
            if (!sp[0] || sp[1] !== "gopomelo.com") {
              if (!sp[0] || sp[1] !== value.domainName) {
                wrongFormat.push(`${key}${i}`);
              }
            }
          });
        }
      }
      if (key === "companyName") {
        // eslint-disable-next-line no-useless-escape
        var format = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (format.test(value[key])) {
          wrongFormat.push(key);
        } else {
          if (by === "create") {
            const companyIndex: any = companiesName?.indexOf(value?.[key]);
            if (companyIndex > -1) {
              wrongFormat.push(key);
            }
          }
        }
      }
      if (key === "domainName") {
        if (
          (value[key].slice(value[key].length - 4, value[key].length) !==
            ".com" &&
            value[key].slice(value[key].length - 6, value[key].length) !==
              ".co.th" &&
            value[key].slice(value[key].length - 6, value[key].length) !==
              ".or.th" &&
            value[key].slice(value[key].length - 6, value[key].length) !==
              ".go.th") ||
          value[key].includes(" ")
        ) {
          wrongFormat.push(key);
        }
      }
    });
    if (wrongFormat.length) {
      setFieldWrongFormat(wrongFormat);
      return;
    }
    if (
      new Date(value.expiryDate) <= new Date(new Date().setHours(0, 0, 0, 0))
    ) {
      setConfirmPopupIsOpen(true);
    } else {
      if (by === "create") {
        setIsLoading({
          status: "inprogress",
          label: "Creating company details...",
        });
      } else if (by === "edit") {
        setIsLoading({
          status: "inprogress",
          label: "Saving company details...",
        });
      }
      const userFilter = value.users.filter((e: any) => e !== "");
      const deletedFilter = value.deleted.filter(
        (e: any) => e && companyDetail?.users.indexOf(e)
      );
      await adminApi.postSave(
        {
          ...value,
          users: userFilter,
          deleted: deletedFilter,
        },
        companyDetail?.id
      );
      handleCreateAndEditCompany(by);
      setCompanyDetail(undefined);
    }
  };
  return (
    <SContainer>
      {confirmPopupIsOpen && (
        <ConfirmPopup
          header="Expiry Date"
          content={`The selected date is expired. 
Are you sure you want to save this date?`}
          confirm={{
            label: "CONFIRM",
            onClick: async () => {
              setConfirmPopupIsOpen(false);
              if (by === "create") {
                setIsLoading({
                  status: "inprogress",
                  label: "Creating company details...",
                });
              } else if (by === "edit") {
                setIsLoading({
                  status: "inprogress",
                  label: "Saving company details...",
                });
              }
              await adminApi.postSave({
                ...value,
              });
              handleCreateAndEditCompany(by);
              setOpenAdminPopup(undefined);
              setCompanyDetail(undefined);
            },
          }}
          cancel={{
            label: "CANCEL",
            onClick: () => {
              setConfirmPopupIsOpen(false);
            },
          }}
        />
      )}
      <SContent className={adminFieldRight.length ? "" : "oneside"}>
        <SHeader>
          <SLabel>
            {by === "create" ? "New Company" : "Edit Company Detail"}
          </SLabel>
          <SExit
            onClick={() => {
              setOpenAdminPopup(undefined);
              setCompanyDetail(undefined);
            }}
          >
            x
          </SExit>
        </SHeader>
        <SField>
          <SSide className={adminFieldRight.length ? "" : "oneside"}>
            {adminFieldLeft.map((e, i) => {
              return handleRenderField(e, i);
            })}
          </SSide>
          {adminFieldRight.length ? (
            <SSide>
              {adminFieldRight.map((e, i) => {
                return handleRenderField(e, i);
              })}
            </SSide>
          ) : (
            ""
          )}
        </SField>
        <SFooter>
          <SBottom>
            <Button
              onclick={() => {
                setCompanyDetail(undefined);
                setOpenAdminPopup(undefined);
              }}
              disable={isLoading?.status === "inprogress" ? true : false}
              addonCss={{
                border: "1px solid #E4E4E4",
                color: "#3C7FEC",
                marginRight: "20px",
                width: "50px",
                height: "20px",
              }}
            >
              {isLoading?.status === "inprogress" ? <Loading /> : "CANCEL"}
            </Button>
            <Button
              onclick={() => handleSave()}
              disable={isLoading?.status === "inprogress" ? true : false}
              addonCss={
                isFieldComplete
                  ? {
                      background: "#3C7FEC",
                      color: "white",
                      width: "50px",
                      height: "20px",
                    }
                  : {
                      background: "#F4F4F4",
                      color: "#404040",
                      cursor: "not-allowed",
                      width: "50px",
                      height: "20px",
                    }
              }
            >
              {isLoading?.status === "inprogress" ? <Loading /> : "SAVE"}
            </Button>
          </SBottom>
        </SFooter>
      </SContent>
    </SContainer>
  );
};
export default AdminPopup;
const SFooter = styled.div`
  position: absolute;
  background-color: white;
  width: 34%;

  bottom: 10vh;
  right: 33vw;
`;
const SAddDomain = styled.div`
  cursor: pointer;
  color: #3c7fec;
  font-weight: 500;
`;
const SIcon = styled.div`
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  & > img {
    width: 20px;
    height: 20px;
  }
`;
const STopic = styled.div`
  font-weight: 500;
`;
const SFieldContainer = styled.div`
  margin-bottom: 20px;
`;
const SAlert = styled.div`
  font-size: 10px;
  color: rgba(0, 0, 0, 0.3);
  color: red;
`;
const SSuggestion = styled.div`
  font-size: 10px;
  color: rgba(0, 0, 0, 0.3);
`;
const SInputContainer = styled.div`
  height: 40px;
  margin: 10px 0px 12px 0px;
  display: flex;
  align-items: center;
  position: relative;
`;
const SField = styled.div`
  /* height: 100%; */
  display: flex;
`;
const SSide = styled.div`
  width: 50%;
  &.oneside {
    width: 100%;
  }
  height: 100%;
  padding: 20px;
`;
const SBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 10px;
`;
const SExit = styled.div`
  cursor: pointer;
  background: #e4e4e4;
  padding: 2px 11px;
  border-radius: 100%;
`;
const SLabel = styled.div`
  font-weight: 500;
  font-size: 20px;
`;
const SHeader = styled.div`
  padding: 20px;
  border-bottom: solid 1px #e4e4e4;
  display: flex;
  justify-content: space-between;
`;
const SContent = styled.div`
  background-color: white;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 80%;
  height: fit-content;
  &.oneside {
    width: 35%;
    height: 80%;
  }
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
`;
const SContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;
