import { useEffect, useState } from "react";
import ReactDatePicker from "react-date-picker";
import styled from "styled-components";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import CalendarIcon from "../images/calendar-icon.png";
interface IDatePicker {
  startDate: string;
  onSelect: Function;
  endDate?: string;
  rangeOn?: boolean;
  addonCss?: any;
}
const DatePicker = ({
  startDate,
  endDate,
  onSelect,
  addonCss = {},
  rangeOn = true,
}: IDatePicker) => {
  const [disableCalendar, setDisableCalendar] = useState(true);
  const [value, onChange] = useState<any>([
    new Date(startDate),
    new Date(endDate ? endDate : ""),
  ]);
  useEffect(() => {
    if (!value) return;
    if (rangeOn) {
      onSelect(value[0], value[1]);
    } else {
      onSelect(value[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <SContainer style={addonCss} className={rangeOn ? "rangeOn" : ""}>
      {rangeOn ? (
        <SDateTimeRangePicker
          onChange={(e: any) => {
            onChange(e);
          }}
          className={disableCalendar ? "disable" : "active"}
          format={"MMM d y h:mm a"}
          closeCalendar={false}
          maxDate={new Date()}
          minDate={new Date(new Date().setDate(new Date().getDate() - 90))}
          clearIcon={<></>}
          onCalendarClose={() => setDisableCalendar(true)}
          calendarIcon={
            <div
              style={{
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setDisableCalendar(false)}
            >
              <img
                style={{ width: "20px", height: "20px" }}
                src={CalendarIcon}
                alt="cld"
              ></img>
            </div>
          }
          value={value}
          disableClock={true}
        />
      ) : (
        <SReactDatePicker
          className={disableCalendar ? "disable" : "active"}
          onChange={(e: any) => onChange([e])}
          value={value[0]}
          onCalendarClose={() => setDisableCalendar(true)}
          clearIcon={<></>}
          calendarIcon={
            <div
              onClick={() => setDisableCalendar(false)}
              style={{
                width: "30px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "20px", height: "20px" }}
                src={CalendarIcon}
                alt="cld"
              ></img>
            </div>
          }
          format={"MMM d y"}
          closeCalendar={false}
        />
      )}
    </SContainer>
  );
};
export default DatePicker;
const SDateTimeRangePicker = styled(DateTimeRangePicker)`
  height: 100%;
  margin-left: 10px;
  & > div.react-datetimerange-picker__wrapper {
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    padding: 0 10px;
  }
  &.disable {
    & > span > div {
      display: none;
    }
  }
  & > span > div {
    & > div {
      &.react-calendar {
        border: none;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 20px;
        & > div.react-calendar__viewContainer {
          & > div > div > div > div > div > abbr {
            text-decoration: none;
          }
          & > div > div > div > div > button {
            color: rgba(0, 0, 0, 0.3);
            &.react-calendar__tile--rangeStart {
              color: white;
              border-top-left-radius: 7px;
              border-bottom-left-radius: 7px;
            }
            &.react-calendar__tile--rangeEnd {
              color: white;
              border-top-right-radius: 7px;
              border-bottom-right-radius: 7px;
            }
            &.react-calendar__tile--active {
              background-color: rgba(60, 127, 236, 0.1);
            }
            &.react-calendar__tile--hasActive {
              background-color: #3c7fec;
            }
            &.react-calendar__tile--now {
              background-color: #cde6cd;
            }
          }
        }
      }
    }
  }
`;
const SReactDatePicker = styled(ReactDatePicker)`
  height: 100%;
  width: 108%;
  & > div {
    &.react-date-picker__wrapper {
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      padding: 0 20px;
    }
  }
  &.disable {
    & > span > div {
      display: none;
    }
  }
  & > span {
    & > div {
      &.react-date-picker__calendar {
        inset: auto auto -310px 0px !important;
      }
      & > div.react-calendar {
        border: none;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 20px;
        & > div.react-calendar__viewContainer {
          & > div > div > div > div > div > abbr {
            text-decoration: none;
          }
          & > div > div > div > div > button {
            color: rgba(0, 0, 0, 0.3);
            &.react-calendar__tile--active {
              background-color: #7198d8b0;
            }
            &.react-calendar__tile--now {
              background-color: #cde6cd;
            }
          }
        }
      }
    }
  }
`;
const SContainer = styled.div`
  position: relative;
  width: 42%;
`;
