import { useContext, useEffect } from "react";
import styled from "styled-components";
import { DropdownContext } from "../../contexts/DropdownContext";

interface IDropdownList {
  dropdownIsOpen: boolean;
  choices: { label: any; icon: any | undefined }[];
  dropdownSelected: any;
  onclick: Function;
  setDropdownIsOpen: Function;
  css?: any;
}
const DropdownList = ({
  dropdownIsOpen,
  choices,
  dropdownSelected,
  onclick,
  setDropdownIsOpen,
  css,
}: IDropdownList) => {
  const { dropdownRef, setDropdownRef } = useContext(DropdownContext);
  useEffect(() => {
    if (!dropdownIsOpen) {
      setDropdownRef(null);
    } else {
      setDropdownRef("dropdown");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownIsOpen]);
  useEffect(() => {
    if (!dropdownRef) {
      setDropdownIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);
  return (
    <>
      {dropdownIsOpen && (
        <SSelector style={css}>
          {choices.map((choice, i) => {
            return (
              <SChoice
                className={choice.label === dropdownSelected ? "selected" : ""}
                key={i}
                onClick={(e) => {
                  e.stopPropagation();
                  onclick(choice.label);
                }}
              >
                {choice.icon && <SIcon alt={choice.label} src={choice.icon} />}
                {choice.label}
              </SChoice>
            );
          })}
        </SSelector>
      )}
    </>
  );
};
export default DropdownList;
const SIcon = styled.img`
  width: 25px;
  margin-right: 20px;
`;
const SSelector = styled.div`
  position: absolute;
  margin-top: 312px;
  margin-left: -21px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 10;
`;
const SChoice = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  &:hover {
    background-color: rgba(60, 127, 236, 0.1);
  }
  &.selected {
    color: #3c7fec;
    font-weight: 500;
  }
`;
