import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Button from "../components/Button";
import ResultTable from "../components/ResultTable";
import TableBar from "../components/TableBar";
import TitleBar from "../components/TitleBar";
import { AdminContext } from "../contexts/AdminContext";
import { IAdmin, ICompanyDetail } from "../interfaces/admin-interface";
import AdminPopup from "../components/AdminPopup";
import { adminApi } from "../apis/admin-api";
import { sortDate } from "../utils/sort";
import { DropdownContext } from "../contexts/DropdownContext";
import SnackBar from "../components/SnackBar";
import { LoadingContext } from "../contexts/LoadingContext";
import { UserContext } from "../contexts/UserContext";
import { IUser } from "../interfaces/user-interface";
import Login from "./Login";

const Admin = () => {
  const { setIsLoading } = useContext(LoadingContext);

  const { openAdminPopup, setOpenAdminPopup } = useContext(AdminContext);
  const { dropdownRef, setDropdownRef } = useContext(DropdownContext);
  const { user, setUser } = useContext(UserContext);
  const handleLogin = (value: IUser) => {
    setUser(value);
  };
  const [companySelected, setCompanySelected] = useState<
    ICompanyDetail | undefined
  >(undefined);
  const [companyList, setCompanyList] = useState<IAdmin[] | undefined>(
    undefined
  );
  const handleGetData = async () => {
    const data: IAdmin[] = await adminApi.getAllDetail();
    const result = await sortDate(data, "expiryDate");
    setCompanyList(result);
  };
  const handleCreateAndEditCompany = async (by: string) => {
    await handleGetData();
    if (by === "create") {
      setIsLoading({
        status: "complete",
        label: "New company has been created.",
      });
    } else if (by === "edit") {
      setIsLoading({
        status: "complete",
        label: "Your change has been successfully saved.",
      });
    }
    setOpenAdminPopup(undefined);
  };
  useEffect(() => {
    document.title = "GoPomelo: Administration Portal";
    handleGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const companiesName = useMemo(() => {
    if (!companyList) return undefined;
    const result = companyList?.map((e) => {
      return e.companyName;
    });
    return result;
  }, [companyList]);
  return (
    <SContainer>
      {user ? (
        <>
          <SnackBar addonCss={{ top: "55px" }} />
          {dropdownRef && (
            <SBackDrop
              onClick={() => {
                setDropdownRef(null);
              }}
            />
          )}
          {openAdminPopup && (
            <AdminPopup
              by={openAdminPopup}
              companyDetail={
                openAdminPopup === "edit" ? companySelected : undefined
              }
              companiesName={companiesName}
              setCompanyDetail={setCompanySelected}
              handleCreateAndEditCompany={handleCreateAndEditCompany}
            />
          )}
          <TitleBar
            title={"Administration Portal"}
            userImageUrl={user?.imageUrl}
            userName={user?.name}
          />
          <SResultContainer>
            <TableBar label="List of Company">
              <Button
                onclick={() => {
                  setOpenAdminPopup("create");
                }}
                addonCss={{
                  color: "#3C7FEC",
                  fontWeight: "500",
                  marginRight: "50px",
                }}
              >
                + New Company
              </Button>
            </TableBar>
            {companyList && (
              <ResultTable
                headers={[
                  "No.",
                  "Company Name",
                  "Domain Name",
                  "Admin Account",
                  "Expiry Date",
                  "Status",
                ]}
                resultOrder={[
                  "no",
                  "companyName",
                  "domainName",
                  "users",
                  "expiryDate",
                  "status",
                  "...",
                ]}
                setKbab={setCompanySelected}
                data={companyList}
                noDataMessage="Click create to create company."
                handleGetData={handleGetData}
              />
            )}
          </SResultContainer>
        </>
      ) : (
        <Login setUserLogin={handleLogin} admin={true} />
      )}
    </SContainer>
  );
};
export default Admin;
const SContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;
const SResultContainer = styled.div`
  padding: 0 70px;
  margin-top: 20px;
  height: 100%;
`;
const SBackDrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
`;
