import React, { useState, createContext, useEffect } from "react";
interface Props {
  children: React.ReactNode;
}
interface ILoadingContext {
  isLoading:
    | { status: "complete" | "inprogress" | "fail"; label: any }
    | undefined;
  setIsLoading: Function;
}
export const LoadingContext = createContext<ILoadingContext>({
  isLoading: undefined,
  setIsLoading: () => {},
});

const LoadingContextProvider: React.FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<
    { status: "complete" | "inprogress" | "fail"; label: any } | undefined
  >(undefined);
  useEffect(() => {
    if (isLoading?.status === "complete" || isLoading?.status === "fail") {
      setTimeout(() => {
        setIsLoading(undefined);
      }, 15000);
    }
  }, [isLoading]);
  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContextProvider;
