import React, { useContext } from "react";
import styled from "styled-components";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { IUser } from "./interfaces/user-interface";
import { UserContext } from "./contexts/UserContext";

function App() {
  const { user, setUser } = useContext(UserContext);
  const handleLogin = (value: IUser) => {
    setUser(value);
  };
  return (
    <SContainer>
      {user ? <Home /> : <Login setUserLogin={handleLogin} />}
    </SContainer>
  );
}

export default App;
const SContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;
